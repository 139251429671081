//
// custom-variables.scss
//

// Shadow
$shadow:                            0px 0px 35px 0px rgba(154, 161, 171, 0.15);
$shadow-lg:                         0 0 45px 0 rgba(0, 0, 0, 0.12);

// Background left-sidebar
$bg-leftbar:                        linear-gradient(135deg, #8f75da 0%, #727cf5 60%);
$bg-leftbar-light:                #ffffff;
$bg-leftbar-dark:                 #313a46;
$bg-topnav:                         linear-gradient(130deg, #5e46be, #4762fa);

// Deatched left sidenav
$bg-detached-leftbar:             #ffffff;

// Topbar Height
$topbar-height:                     70px;

// Leftbar width
$leftbar-width:                     250px;

// Boxed Layout
$boxed-layout-width:                1300px;
$boxed-layout-bg:                 #ffffff;

// Menu item colors (Default-dark)
$menu-item:                       #cedce4;
$menu-item-hover:                 #ffffff;
$menu-item-active:                #ffffff;

// Menu item colors (light)
$menu-item-light:                 #6c757d;
$menu-item-light-hover:           #727cf5;
$menu-item-light-active:          #727cf5;

// Detached Menu item colors
$menu-item-dark:                  #6c757d;
$menu-item-dark-hover:            #727cf5;
$menu-item-dark-active:           #727cf5;

// Dark sidenav Menu item color
$sidebar-dark-menu-item:          #8391a2;
$sidebar-dark-menu-item-hover:    #bccee4;
$sidebar-dark-menu-item-active:   #ffffff;

// Rightbar Width
$rightbar-width:       260px;
$rightbar-bg:                     #ffffff;
$rightbar-title-bg:               #313a46;
$rightbar-title-color:            #ffffff;
$rightbar-title-btn-bg:           #444e5a;
$rightbar-title-btn-color:        #ffffff;
$rightbar-overlay-bg:             #37404a;

// Topbar Background
$bg-topbar:                       #ffffff;
$bg-topbar-dark:                  #313a46;

// Topbar Search
$bg-topbar-search:                #f1f3fa;
$bg-topbar-dark-search:           #3c4655;

// Font weight
$font-weight-semibold:              600;

// Dropdown Large
$dropdown-lg-width:                 320px;

// Page title color
$page-title-color:                  inherit;

// Nav-pill background
$nav-pills-bg:                    #e3eaef;

// Custom-accordion
$custom-accordion-title-color:    #313a46;

// Dragula demo background
$dragula-bg:                      #e3eaef;

// Form wizard header background
$form-wizard-header-bg:           #e3eaef;

// Text title color
$text-title-color:                #6c757d;

// card Loader background
$card-loader-bg:                  #313a46;

// Chat widget
$chat-primary-user-bg:            #fef5e4;
$chat-secondary-user-bg:          #f1f3fa;

// User authentication Background
$auth-bg:                         #ffffff;
$auth-bg-pattern-img:               url("/assets/images/bg-pattern-light.svg");