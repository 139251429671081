body #root {
  white-space: pre-line; // Needed to allow line break in translations \n
}

.account-pages .card-header {
  background-image: $bg-topnav;
}

.modal-page .card-header {
  background-image: $bg-topnav;
}

.rule_close_btn {
  font-size: 2em;
  float: right;
  position: absolute;
  right: 5px;
  margin-top: -15px;

  i {
    color: #cecece;

    &:hover {
      color: #6c757d;
    }
  }
}

.form-group-mb-0 {
  .form-group {
    margin-bottom: 0;
  }
}

table.table {
  .action-icon {
    font-size: 1.3em;
    color: #6c757d;

    &:hover {
      color: $primary !important;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

#menu-bar .side-nav-link > i {
  font-size: 1.3em;
  vertical-align: text-bottom;
}

.nav-user {
  background-color: transparent;
}

.dropdown-domains button {
  color: $primary !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-register-sign{
  font-weight: 600;
  display: inline-block;
  margin-left: 10px;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 20px;
  color: #fff;
  background-color: #4762fa;
  padding: 0.25rem 1rem;
}
.btn-register-sign:hover{
  color: #fff;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.edited-icon {
  margin-right: 3px;
  font-size: 20px;
  vertical-align: middle;
}

.font-40 {
  font-size: 40px;
}
.documentation-frame {
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
}

.overflow-visible {
  overflow: visible !important;
}

.preview-content {
  position: sticky;
  top: 20px;
}

.gap-3 {
  gap: 3px;
}

.font-40 {
  font-size: 40px;
}

.trial-message {
  background-color: #727cf5;
  border-color: #939aeb;
  color: white;
  font-size: 14px;
  font-weight: bold;

  a {
    text-decoration: underline;
  }

  p {
    margin-bottom: 0;
  }

  .close {
    color: white;
    opacity: 1;
  }
}

.trial-message-alert {
  display: flex;
  padding: 10px;
  background-color: #d1e7dd;
  border-color: #0f5132;
  border-radius: 4px;
  font-weight: bold;
  font-size: 15px;
  margin: 5px 0;

  i {
    margin-right: 5px;
  }
}

.subscriptions-list {
  td {
    vertical-align: middle;
  }
}

.alert-warning-light {
  color: #646464;
  border: 1px solid #fbd1bb;
}

.platform {
  .icon {
    padding: 0.3rem 0 0.3rem 0.9rem;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  input {
    border-left: 0;
  }
}

.domain-wizard-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  gap: 0.25rem;

  .step {
    font-size: 16px;
    font-weight: 600;
    border: 1px solid hsl(0,0%,80%);
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background-color: white;
    border-radius: 0.25rem;
  }

  .step-active {
    background-color: $primary;
    color: white;
    border-color: $primary;
  }

  .crumbs:after {
    content: '>';
    margin: 0 0.5rem;
  }
}

.domain-wizard-entri-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  text-align: center;

  .row {
    flex-direction: row;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media (min-width: 768px) {
    img {
      width: fit-content;
    }
  }
}


.flex-center {
  justify-content: center;
  align-items: center;
}

.mdi-larger::before {
  font-size: 1.4em !important;
}

#paypal-button-container {
  min-width: 300px;
  min-height: 2rem;
}

.payment-card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 4px;

  position: relative;

  /* Border */
  border: 1px solid #cccccc;

  min-width: 26rem;
  max-width: 26rem;

  min-height: 10rem;

  &.add-new {
    justify-content: center;
    align-content: center;
  }

  &.dashed {
    border-style: dashed;
  }
}

.payment-card-badge {
  /* Badge is at top right of container, ignoring flex */
  position: absolute;
  top: 0;
  right: 0;

  margin-top: 0.5rem;
  margin-right: 0.5rem;

  text-align: center;
  font-size: 0.8rem;
  font-weight: 700;

  border-radius: 5px;

  padding: 0.25rem 0.75rem;

  color: #ffffff;
  background-color: $indigo;

  &.green {
    background-color: #3fa94d;
  }
}

.payment-card-logo {
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 108px;
  max-width: 108px;

  img {
    width: auto;
    height: 24px;
  }
}

.payment-card-buttons {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  gap: 0.5rem;

  border: 0px;
  border-top: 1px solid #cccccc;

  &.dashed {
    border-style: dashed;
  }
}

@media (max-width: 525px) {
  #paypal-button-container {
    min-width: 100%;
  }
}

@media (max-width: 1000px) {
  #paypal-button-container {
    min-width: 500px;
  }
}

@media (max-width: 494px) {
  .payment-card-container {
    min-width: 100%;
    max-width: 100%;
  }
}

.paypal-banner-container {
  display: block;
  position: relative;
}

.paypal-close-container {
  display: block;
  position: absolute;
  right: 0px;
  margin-right: 0.5rem;
  margin-top: 0.25rem;

  > i {
    font-size: 1.5rem;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  }
}
